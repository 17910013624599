import {
  BAVBenefitDetailsDTO,
  BenefitDTO,
  BenefitDTOBenefitEnum,
  BikeBenefitDetailsDTO,
  BikeBenefitDetailsDTOEmployerExtrasEnum,
  BikeleasingBenefitDetailsDTO,
  CouponBenefitDetailsDTO,
  CouponsV2BenefitDetailsDTO,
  CustomBenefitDetailsDTO,
  FitnessBenefitDetailsDTO,
  FitnessBenefitDetailsDTOStatusEnum,
  FlexBenefitDetailsDTO,
  InternetBenefitDetailsDTO,
  LunchBenefitDetailsDTO,
  MobilityBenefitDetailsDTO,
  NewsBenefitDetailsDTO,
  RecreationBenefitDetailsDTO,
} from 'probonio-shared-ui/api';

import { Divider, Grid2, Paper, Typography } from '@mui/material';
import { BENEFIT_PICTURES, BenefitButton } from 'probonio-shared-ui/component/button';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NavigationButton from '../../component/button/NavigationButton';
import { BENEFIT_ICONS } from './BenefitIcon';
import { getFitnessStatus } from '../fitness/useFitnessState';
import { DateTime } from 'luxon';

interface BenefitItemButtonProps {
  icon: typeof BENEFIT_ICONS.LUNCH;
  title: string;
  picture: string;
  navigateTo: string;
  benefitProgress: number;

  descriptionKey?: string;
  descriptionValues?: object;
  badgeValue?: number;
}

const BenefitItemButton: React.FC<BenefitItemButtonProps> = ({
  icon,
  title,
  navigateTo,
  picture,
  benefitProgress,
  descriptionKey,
  descriptionValues,
  badgeValue,
}) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(navigateTo);
  }, [navigate, navigateTo]);

  const Icon = icon;

  return (
    <BenefitButton
      title={title}
      onClick={handleNavigate}
      icon={Icon}
      picture={picture}
      benefitProgress={benefitProgress}
      descriptionKey={descriptionKey}
      descriptionValues={descriptionValues}
      badgeValue={badgeValue}
    />
  );
};

const BenefitItemCard: React.FC<React.PropsWithChildren<BenefitItemButtonProps>> = ({
  icon,
  title,
  navigateTo,
  picture,
  benefitProgress,
  descriptionKey,
  descriptionValues,
  children,
}) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(navigateTo);
  }, [navigate, navigateTo]);

  const Icon = icon;
  return (
    <Paper>
      <BenefitButton
        title={title}
        onClick={handleNavigate}
        icon={Icon}
        picture={picture}
        benefitProgress={benefitProgress}
        descriptionKey={descriptionKey}
        descriptionValues={descriptionValues}
        sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      />
      {children}
    </Paper>
  );
};

const BenefitCardNavButton: React.FC<BenefitItemButtonProps> = ({ icon, title, navigateTo, descriptionKey, descriptionValues }) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(navigateTo);
  }, [navigate, navigateTo]);

  const Icon = icon;

  return (
    <NavigationButton iconLeft={<Icon />} onClick={handleNavigate}>
      <Typography variant="h3" color="text.primary">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <Trans i18nKey={descriptionKey} values={descriptionValues}>
          <Typography component="span" variant="inherit" fontWeight={700} color="success.main" />
          <Typography component="span" variant="inherit" fontWeight={700} />
        </Trans>
      </Typography>
    </NavigationButton>
  );
};

interface Props {
  activeBenefit: BenefitDTO;
  allBenefits: BenefitDTO[];
  renderer?: React.ComponentType<BenefitItemButtonProps>;
}

export const BenefitItem: React.FC<Props> = ({ activeBenefit, allBenefits, renderer }) => {
  const { t } = useTranslation('benefitModule');

  const ButtonComponent = renderer || BenefitItemButton;

  switch (activeBenefit.benefit) {
    case BenefitDTOBenefitEnum.Lunch:
      const detailsLunch = activeBenefit.details as LunchBenefitDetailsDTO;
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo="/lunch"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.description`}
          descriptionValues={{ refund: detailsLunch.refund, maxRefund: detailsLunch.maxRefund }}
          benefitProgress={(detailsLunch.refund / detailsLunch.maxRefund) * 100}
        />
      );
    case BenefitDTOBenefitEnum.Coupons:
      // find benefit override details
      const detailsCoupons = activeBenefit.details as CouponBenefitDetailsDTO;
      const totalAmountLeft = detailsCoupons.totalAmountLeft + (detailsCoupons.coupons.length > 0 ? detailsCoupons.coupons[0].amount : 0);
      const usedBudget = detailsCoupons.usedMonthlyBudget;

      const couponBudget = detailsCoupons.couponMonthlyBudget ? detailsCoupons.couponMonthlyBudget - detailsCoupons.usedMonthlyBudget : 0;

      if (detailsCoupons.alternateBenefits.length) {
        return (
          <BenefitItemCard
            icon={BENEFIT_ICONS[activeBenefit.benefit]}
            title={t(`benefit.${activeBenefit.benefit}.alternate.name`)}
            navigateTo={detailsCoupons.isCouponsVisible ? '/coupons' : `/${detailsCoupons.alternateBenefits[0].toLowerCase()}`}
            picture={BENEFIT_PICTURES[activeBenefit.benefit]}
            descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.alternate.summary`}
            descriptionValues={{ usedBudget, monthlyBudget: detailsCoupons.couponMonthlyBudget }}
            benefitProgress={usedBudget ? 100 : 0}
          >
            <Grid2>
              {detailsCoupons.isCouponsVisible && (
                <>
                  <BenefitCardNavButton
                    icon={BENEFIT_ICONS[activeBenefit.benefit]}
                    title={t(`benefit.${activeBenefit.benefit}.name`)}
                    navigateTo="/coupons"
                    picture={BENEFIT_PICTURES[activeBenefit.benefit]}
                    descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.alternate.couponBudgetAvailable`}
                    descriptionValues={{ availableBudget: couponBudget, totalAmountLeft: detailsCoupons.totalAmountLeft }}
                    benefitProgress={0}
                  />
                  <Divider sx={{ mx: 0.75, borderWidth: 0, borderBottomWidth: 'thin' }} />
                </>
              )}
              {detailsCoupons.alternateBenefits
                .map(benefit => allBenefits.find(dto => dto.benefit === benefit))
                .filter(benefitDto => !!benefitDto)
                .map((benefitDto, index, array) => (
                  <>
                    {index < array.length - 1 && <Divider sx={{ mx: 0.75, borderWidth: 0, borderBottomWidth: 'thin' }} />}
                    <BenefitItem renderer={BenefitCardNavButton} activeBenefit={benefitDto!} allBenefits={allBenefits} />
                  </>
                ))}
            </Grid2>
          </BenefitItemCard>
        );
      }
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo={'/coupons'}
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.description.couponsAvailable`}
          descriptionValues={{ count: totalAmountLeft }}
          benefitProgress={detailsCoupons.coupons.length === 0 ? 100 : 0}
        />
      );

    case BenefitDTOBenefitEnum.CouponsV2:
      const detailsCouponsV2 = activeBenefit.details as CouponsV2BenefitDetailsDTO;
      const totalAmountLeftV2 =
        detailsCouponsV2.totalAmountLeft + detailsCouponsV2.availableTokens.reduce((sum, { amount }) => sum + amount, 0);

      return (
        <>
          <ButtonComponent
            icon={BENEFIT_ICONS[activeBenefit.benefit]}
            title={t(`benefit.${activeBenefit.benefit}.name`)}
            navigateTo={'/couponsV2'}
            picture={BENEFIT_PICTURES[activeBenefit.benefit]}
            descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.description.couponsAvailable`}
            descriptionValues={{ count: totalAmountLeftV2 }}
            benefitProgress={detailsCouponsV2.availableTokens.length === 0 ? 100 : 0}
          />
        </>
      );
    case BenefitDTOBenefitEnum.Mobility:
      const detailsMobility = activeBenefit.details as MobilityBenefitDetailsDTO;
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo="/mobility"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.description`}
          descriptionValues={{ refund: detailsMobility.refund, maxRefund: detailsMobility.maxRefund }}
          benefitProgress={(detailsMobility.refund / detailsMobility.maxRefund) * 100}
        />
      );
    case BenefitDTOBenefitEnum.Internet:
      const detailsInternet = activeBenefit.details as InternetBenefitDetailsDTO;
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo="/internet"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.description`}
          descriptionValues={{ refund: detailsInternet.total }}
          benefitProgress={detailsInternet.total ? 100 : 0}
        />
      );
    case BenefitDTOBenefitEnum.Bav:
      const detailsBAV = activeBenefit.details as BAVBenefitDetailsDTO;

      const totalPremium =
        (detailsBAV.activeContract?.employeePremiumGross || 0) +
        (detailsBAV.activeContract?.employerGrant || 0) +
        (detailsBAV.activeContract?.vwlConversion || 0);
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.${detailsBAV.activeContract ? 'name' : 'nameNoContract'}`)}
          navigateTo="/bav"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.${
            detailsBAV.activeContract ? 'description' : 'descriptionNoContract'
          }`}
          descriptionValues={{ totalPremium }}
          benefitProgress={detailsBAV.activeContract ? 100 : 0}
        />
      );
    case BenefitDTOBenefitEnum.Bike:
      const detailsBike = activeBenefit.details as BikeBenefitDetailsDTO;

      const serviceAmount = detailsBike.employerExtras.includes(BikeBenefitDetailsDTOEmployerExtrasEnum.Inspection)
        ? detailsBike.activeContract?.monthlyServiceRate || 0
        : 0;

      const insuranceAmount = detailsBike.employerExtras.includes(BikeBenefitDetailsDTOEmployerExtrasEnum.Insurance)
        ? detailsBike.activeContract?.monthlyInsuranceRate || 0
        : 0;

      const total = serviceAmount + insuranceAmount + detailsBike.employerGrant;

      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.${detailsBike.activeContract ? 'name' : 'nameNoContract'}`)}
          navigateTo="/bike"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.${
            detailsBike.activeContract && total > 0 ? 'description' : 'descriptionNoContract'
          }`}
          descriptionValues={{ total }}
          benefitProgress={detailsBike.activeContract ? 100 : 0}
        />
      );
    case BenefitDTOBenefitEnum.News:
      const detailsNews = activeBenefit.details as NewsBenefitDetailsDTO;
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo="/news"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.${detailsNews.unreadArticles ? 'description' : 'noArticles'}`}
          descriptionValues={{ count: detailsNews.unreadArticles }}
          benefitProgress={100}
          badgeValue={detailsNews.unreadArticles}
        />
      );
    case BenefitDTOBenefitEnum.Flex:
      const detailsFlex = activeBenefit.details as FlexBenefitDetailsDTO;
      return (
        <BenefitItemCard
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo="/flex"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.description`}
          descriptionValues={{ usedBudget: detailsFlex.usedBudget, monthlyBudget: detailsFlex.monthlyBudget }}
          benefitProgress={(detailsFlex.usedBudget / detailsFlex.monthlyBudget) * 100}
        >
          {detailsFlex.flexBenefits
            .map(benefit => allBenefits.find(dto => dto.benefit === benefit))
            .filter(benefitDto => !!benefitDto)
            .map((benefitDto, index, array) => (
              <Grid2 key={`${benefitDto?.benefit}-${benefitDto.details}`}>
                <BenefitItem renderer={BenefitCardNavButton} activeBenefit={benefitDto!} allBenefits={allBenefits} />
                {index < array.length - 1 && <Divider sx={{ mx: 0.75, borderWidth: 0, borderBottomWidth: 'thin' }} />}
              </Grid2>
            ))}
        </BenefitItemCard>
      );
    case BenefitDTOBenefitEnum.Recreation:
      const detailsRecreation = activeBenefit.details as RecreationBenefitDetailsDTO;
      const maxRefund = detailsRecreation.isFlexBenefit ? detailsRecreation.maxMonthlyRefund! : detailsRecreation.maxRefund;
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo="/recreation"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={`benefitModule:benefit.${activeBenefit.benefit}.description`}
          descriptionValues={{
            refund: detailsRecreation.isFlexBenefit ? detailsRecreation.refundCurrentMonth : detailsRecreation.refund,
            maxRefund: maxRefund,
          }}
          benefitProgress={(detailsRecreation.refund / maxRefund) * 100}
        />
      );
    case BenefitDTOBenefitEnum.Custom:
      const detailCustom = activeBenefit.details as CustomBenefitDetailsDTO;
      const monthlyBudget = detailCustom.monthlyGrant;

      return (
        <ButtonComponent
          icon={CUSTOM_BENEFIT_ICONS[detailCustom.iconName]}
          title={t(`benefit.${activeBenefit.benefit}.name`, { ...detailCustom })}
          navigateTo={`/custom/${detailCustom.customBenefitId}`}
          picture={detailCustom.imageURL}
          benefitProgress={100}
          descriptionKey={monthlyBudget ? `benefitModule:benefit.${activeBenefit.benefit}.description` : undefined}
          descriptionValues={{ maxRefund: monthlyBudget }}
        />
      );
    case BenefitDTOBenefitEnum.Bikeleasing:
      const bikeleasingDetails = activeBenefit.details as BikeleasingBenefitDetailsDTO;

      const hasBikeleasingUser = !!bikeleasingDetails.bikeleasingEmployeeInformation.length;
      const hasBikeleasingContract = !!bikeleasingDetails.bikeleasingContract.length;

      const descriptionKey =
        (hasBikeleasingContract && `benefitModule:benefit.${activeBenefit.benefit}.description.info`) ||
        (hasBikeleasingUser && `benefitModule:benefit.${activeBenefit.benefit}.description.order`) ||
        `benefitModule:benefit.${activeBenefit.benefit}.description.register`;
      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[activeBenefit.benefit]}
          title={t(`benefit.${activeBenefit.benefit}.name`)}
          navigateTo="/bikeleasing"
          picture={BENEFIT_PICTURES[activeBenefit.benefit]}
          descriptionKey={descriptionKey}
          descriptionValues={{ total: bikeleasingDetails.bikeleasingCondition.priceRestrictions?.maxTotalPurchasePriceForAllBikes }}
          benefitProgress={bikeleasingDetails.bikeleasingEmployeeInformation.length ? 100 : 0}
        />
      );

    case BenefitDTOBenefitEnum.Fitness:
      const detailsFitness = activeBenefit.details as FitnessBenefitDetailsDTO;
      const isActive =
        detailsFitness.status === FitnessBenefitDetailsDTOStatusEnum.Active ||
        detailsFitness.status === FitnessBenefitDetailsDTOStatusEnum.Expiring;
      const fitnessStatus = getFitnessStatus(detailsFitness.status, detailsFitness.monthlyBudget);

      return (
        <ButtonComponent
          icon={BENEFIT_ICONS[detailsFitness.benefit]}
          title={t(`benefit.${detailsFitness.benefit}.name`)}
          navigateTo={`/fitness`}
          picture={BENEFIT_PICTURES[detailsFitness.benefit]}
          descriptionKey={`benefitModule:benefit.${detailsFitness.benefit}.headerStatus.${fitnessStatus}`}
          descriptionValues={{
            startDate: new Date(detailsFitness.startDate),
            endDate: detailsFitness.endDate ? DateTime.fromISO(detailsFitness.endDate).plus({ month: 1 }).toJSDate() : undefined,
          }}
          benefitProgress={isActive ? 100 : 0}
        />
      );
    default:
      return null;
  }
};
