/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateEmployeeDTO } from '../dto';
// @ts-ignore
import { EmployeeDTO } from '../dto';
// @ts-ignore
import { EmployeeListDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { SyncEmployeesDTO } from '../dto';
// @ts-ignore
import { SyncEmployeesResultDTO } from '../dto';
// @ts-ignore
import { UpdateEmployeeDTO } from '../dto';
/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates and invites a new employee
         * @param {string} tenantId 
         * @param {CreateEmployeeDTO} createEmployeeDTO 
         * @param {boolean} [sendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee: async (tenantId: string, createEmployeeDTO: CreateEmployeeDTO, sendMail?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createEmployee', 'tenantId', tenantId)
            // verify required parameter 'createEmployeeDTO' is not null or undefined
            assertParamExists('createEmployee', 'createEmployeeDTO', createEmployeeDTO)
            const localVarPath = `/tenants/{tenantId}/employees`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (sendMail !== undefined) {
                localVarQueryParameter['sendMail'] = sendMail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee: async (tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteEmployee', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteEmployee', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download all employees of tenant as excel sheet
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmployees: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('exportEmployees', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/employees/sync/export`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get an employee by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {boolean} [includeBenefits] 
         * @param {boolean} [includeRegistrationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeById: async (tenantId: string, employeeId: string, includeBenefits?: boolean, includeRegistrationToken?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getEmployeeById', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getEmployeeById', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (includeBenefits !== undefined) {
                localVarQueryParameter['includeBenefits'] = includeBenefits;
            }

            if (includeRegistrationToken !== undefined) {
                localVarQueryParameter['includeRegistrationToken'] = includeRegistrationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sends the invitation mail of all employees using the given filters
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUsers: async (tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('inviteUsers', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/employees/inviteUsers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (considerFutureActivations !== undefined) {
                localVarQueryParameter['considerFutureActivations'] = considerFutureActivations;
            }

            if (activeBenefit !== undefined) {
                localVarQueryParameter['activeBenefit'] = activeBenefit;
            }

            if (activeCustomBenefitId !== undefined) {
                localVarQueryParameter['activeCustomBenefitId'] = activeCustomBenefitId;
            }

            if (inactiveBenefit !== undefined) {
                localVarQueryParameter['inactiveBenefit'] = inactiveBenefit;
            }

            if (inactiveCustomBenefitId !== undefined) {
                localVarQueryParameter['inactiveCustomBenefitId'] = inactiveCustomBenefitId;
            }

            if (userStatus !== undefined) {
                localVarQueryParameter['userStatus'] = userStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (showActiveUser !== undefined) {
                localVarQueryParameter['showActiveUser'] = showActiveUser;
            }

            if (excludeEmployeeStatus) {
                localVarQueryParameter['excludeEmployeeStatus'] = excludeEmployeeStatus;
            }

            if (onlyExpiredRegistrations !== undefined) {
                localVarQueryParameter['onlyExpiredRegistrations'] = onlyExpiredRegistrations;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (costCenter !== undefined) {
                localVarQueryParameter['costCenter'] = costCenter;
            }

            if (confirmedNewsArticleId !== undefined) {
                localVarQueryParameter['confirmedNewsArticleId'] = confirmedNewsArticleId;
            }

            if (unconfirmedNewsArticleId !== undefined) {
                localVarQueryParameter['unconfirmedNewsArticleId'] = unconfirmedNewsArticleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list employees
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {boolean} [includeBenefits] true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
         * @param {boolean} [includeUpcoming] true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [includeActivationFor] true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
         * @param {string} [includeActivationForCustomBenefitId] id of custom benefit for includeActivationFor
         * @param {string} [includeStatusForNewsArticleId] id of news article to include view status for
         * @param {Array<'status'>} [aggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployees: async (tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, includeBenefits?: boolean, includeUpcoming?: boolean, includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', includeActivationForCustomBenefitId?: string, includeStatusForNewsArticleId?: string, aggregations?: Array<'status'>, sortBy?: Array<string>, page?: number, pageSize?: number, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listEmployees', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/employees`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (considerFutureActivations !== undefined) {
                localVarQueryParameter['considerFutureActivations'] = considerFutureActivations;
            }

            if (activeBenefit !== undefined) {
                localVarQueryParameter['activeBenefit'] = activeBenefit;
            }

            if (activeCustomBenefitId !== undefined) {
                localVarQueryParameter['activeCustomBenefitId'] = activeCustomBenefitId;
            }

            if (inactiveBenefit !== undefined) {
                localVarQueryParameter['inactiveBenefit'] = inactiveBenefit;
            }

            if (inactiveCustomBenefitId !== undefined) {
                localVarQueryParameter['inactiveCustomBenefitId'] = inactiveCustomBenefitId;
            }

            if (userStatus !== undefined) {
                localVarQueryParameter['userStatus'] = userStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (showActiveUser !== undefined) {
                localVarQueryParameter['showActiveUser'] = showActiveUser;
            }

            if (excludeEmployeeStatus) {
                localVarQueryParameter['excludeEmployeeStatus'] = excludeEmployeeStatus;
            }

            if (onlyExpiredRegistrations !== undefined) {
                localVarQueryParameter['onlyExpiredRegistrations'] = onlyExpiredRegistrations;
            }

            if (includeBenefits !== undefined) {
                localVarQueryParameter['includeBenefits'] = includeBenefits;
            }

            if (includeUpcoming !== undefined) {
                localVarQueryParameter['includeUpcoming'] = includeUpcoming;
            }

            if (includeActivationFor !== undefined) {
                localVarQueryParameter['includeActivationFor'] = includeActivationFor;
            }

            if (includeActivationForCustomBenefitId !== undefined) {
                localVarQueryParameter['includeActivationForCustomBenefitId'] = includeActivationForCustomBenefitId;
            }

            if (includeStatusForNewsArticleId !== undefined) {
                localVarQueryParameter['includeStatusForNewsArticleId'] = includeStatusForNewsArticleId;
            }

            if (aggregations) {
                localVarQueryParameter['aggregations'] = aggregations;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (costCenter !== undefined) {
                localVarQueryParameter['costCenter'] = costCenter;
            }

            if (confirmedNewsArticleId !== undefined) {
                localVarQueryParameter['confirmedNewsArticleId'] = confirmedNewsArticleId;
            }

            if (unconfirmedNewsArticleId !== undefined) {
                localVarQueryParameter['unconfirmedNewsArticleId'] = unconfirmedNewsArticleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resends the invitation mail of an employee in PENDING status
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitationMail: async (tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('resendInvitationMail', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('resendInvitationMail', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/resendInvitationMail`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync employees and benefit activations with passed data
         * @param {string} tenantId 
         * @param {SyncEmployeesDTO} syncEmployeesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEmployees: async (tenantId: string, syncEmployeesDTO: SyncEmployeesDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('syncEmployees', 'tenantId', tenantId)
            // verify required parameter 'syncEmployeesDTO' is not null or undefined
            assertParamExists('syncEmployees', 'syncEmployeesDTO', syncEmployeesDTO)
            const localVarPath = `/tenants/{tenantId}/employees/sync`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncEmployeesDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sets all employees to ACTIVE which are in REGISTERED status
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {boolean} [includeBenefits] true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
         * @param {boolean} [includeUpcoming] true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [includeActivationFor] true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
         * @param {string} [includeActivationForCustomBenefitId] id of custom benefit for includeActivationFor
         * @param {string} [includeStatusForNewsArticleId] id of news article to include view status for
         * @param {Array<'status'>} [aggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAllRegisteredUsers: async (tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, includeBenefits?: boolean, includeUpcoming?: boolean, includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', includeActivationForCustomBenefitId?: string, includeStatusForNewsArticleId?: string, aggregations?: Array<'status'>, sortBy?: Array<string>, page?: number, pageSize?: number, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('unlockAllRegisteredUsers', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/employees/unlockRegisteredUsers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (considerFutureActivations !== undefined) {
                localVarQueryParameter['considerFutureActivations'] = considerFutureActivations;
            }

            if (activeBenefit !== undefined) {
                localVarQueryParameter['activeBenefit'] = activeBenefit;
            }

            if (activeCustomBenefitId !== undefined) {
                localVarQueryParameter['activeCustomBenefitId'] = activeCustomBenefitId;
            }

            if (inactiveBenefit !== undefined) {
                localVarQueryParameter['inactiveBenefit'] = inactiveBenefit;
            }

            if (inactiveCustomBenefitId !== undefined) {
                localVarQueryParameter['inactiveCustomBenefitId'] = inactiveCustomBenefitId;
            }

            if (userStatus !== undefined) {
                localVarQueryParameter['userStatus'] = userStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (showActiveUser !== undefined) {
                localVarQueryParameter['showActiveUser'] = showActiveUser;
            }

            if (excludeEmployeeStatus) {
                localVarQueryParameter['excludeEmployeeStatus'] = excludeEmployeeStatus;
            }

            if (onlyExpiredRegistrations !== undefined) {
                localVarQueryParameter['onlyExpiredRegistrations'] = onlyExpiredRegistrations;
            }

            if (includeBenefits !== undefined) {
                localVarQueryParameter['includeBenefits'] = includeBenefits;
            }

            if (includeUpcoming !== undefined) {
                localVarQueryParameter['includeUpcoming'] = includeUpcoming;
            }

            if (includeActivationFor !== undefined) {
                localVarQueryParameter['includeActivationFor'] = includeActivationFor;
            }

            if (includeActivationForCustomBenefitId !== undefined) {
                localVarQueryParameter['includeActivationForCustomBenefitId'] = includeActivationForCustomBenefitId;
            }

            if (includeStatusForNewsArticleId !== undefined) {
                localVarQueryParameter['includeStatusForNewsArticleId'] = includeStatusForNewsArticleId;
            }

            if (aggregations) {
                localVarQueryParameter['aggregations'] = aggregations;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (costCenter !== undefined) {
                localVarQueryParameter['costCenter'] = costCenter;
            }

            if (confirmedNewsArticleId !== undefined) {
                localVarQueryParameter['confirmedNewsArticleId'] = confirmedNewsArticleId;
            }

            if (unconfirmedNewsArticleId !== undefined) {
                localVarQueryParameter['unconfirmedNewsArticleId'] = unconfirmedNewsArticleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeDTO} updateEmployeeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (tenantId: string, employeeId: string, updateEmployeeDTO: UpdateEmployeeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateEmployee', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateEmployee', 'employeeId', employeeId)
            // verify required parameter 'updateEmployeeDTO' is not null or undefined
            assertParamExists('updateEmployee', 'updateEmployeeDTO', updateEmployeeDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates and invites a new employee
         * @param {string} tenantId 
         * @param {CreateEmployeeDTO} createEmployeeDTO 
         * @param {boolean} [sendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployee(tenantId: string, createEmployeeDTO: CreateEmployeeDTO, sendMail?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployee(tenantId, createEmployeeDTO, sendMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployee(tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployee(tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary download all employees of tenant as excel sheet
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEmployees(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEmployees(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get an employee by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {boolean} [includeBenefits] 
         * @param {boolean} [includeRegistrationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeById(tenantId: string, employeeId: string, includeBenefits?: boolean, includeRegistrationToken?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeById(tenantId, employeeId, includeBenefits, includeRegistrationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sends the invitation mail of all employees using the given filters
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUsers(tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUsers(tenantId, considerFutureActivations, activeBenefit, activeCustomBenefitId, inactiveBenefit, inactiveCustomBenefitId, userStatus, status, showActiveUser, excludeEmployeeStatus, onlyExpiredRegistrations, filter, department, costCenter, confirmedNewsArticleId, unconfirmedNewsArticleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list employees
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {boolean} [includeBenefits] true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
         * @param {boolean} [includeUpcoming] true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [includeActivationFor] true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
         * @param {string} [includeActivationForCustomBenefitId] id of custom benefit for includeActivationFor
         * @param {string} [includeStatusForNewsArticleId] id of news article to include view status for
         * @param {Array<'status'>} [aggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEmployees(tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, includeBenefits?: boolean, includeUpcoming?: boolean, includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', includeActivationForCustomBenefitId?: string, includeStatusForNewsArticleId?: string, aggregations?: Array<'status'>, sortBy?: Array<string>, page?: number, pageSize?: number, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEmployees(tenantId, considerFutureActivations, activeBenefit, activeCustomBenefitId, inactiveBenefit, inactiveCustomBenefitId, userStatus, status, showActiveUser, excludeEmployeeStatus, onlyExpiredRegistrations, includeBenefits, includeUpcoming, includeActivationFor, includeActivationForCustomBenefitId, includeStatusForNewsArticleId, aggregations, sortBy, page, pageSize, filter, department, costCenter, confirmedNewsArticleId, unconfirmedNewsArticleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary resends the invitation mail of an employee in PENDING status
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendInvitationMail(tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendInvitationMail(tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync employees and benefit activations with passed data
         * @param {string} tenantId 
         * @param {SyncEmployeesDTO} syncEmployeesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncEmployees(tenantId: string, syncEmployeesDTO: SyncEmployeesDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncEmployeesResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncEmployees(tenantId, syncEmployeesDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sets all employees to ACTIVE which are in REGISTERED status
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {boolean} [includeBenefits] true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
         * @param {boolean} [includeUpcoming] true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [includeActivationFor] true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
         * @param {string} [includeActivationForCustomBenefitId] id of custom benefit for includeActivationFor
         * @param {string} [includeStatusForNewsArticleId] id of news article to include view status for
         * @param {Array<'status'>} [aggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockAllRegisteredUsers(tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, includeBenefits?: boolean, includeUpcoming?: boolean, includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', includeActivationForCustomBenefitId?: string, includeStatusForNewsArticleId?: string, aggregations?: Array<'status'>, sortBy?: Array<string>, page?: number, pageSize?: number, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockAllRegisteredUsers(tenantId, considerFutureActivations, activeBenefit, activeCustomBenefitId, inactiveBenefit, inactiveCustomBenefitId, userStatus, status, showActiveUser, excludeEmployeeStatus, onlyExpiredRegistrations, includeBenefits, includeUpcoming, includeActivationFor, includeActivationForCustomBenefitId, includeStatusForNewsArticleId, aggregations, sortBy, page, pageSize, filter, department, costCenter, confirmedNewsArticleId, unconfirmedNewsArticleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeDTO} updateEmployeeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(tenantId: string, employeeId: string, updateEmployeeDTO: UpdateEmployeeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(tenantId, employeeId, updateEmployeeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeApiFp(configuration)
    return {
        /**
         * 
         * @summary creates and invites a new employee
         * @param {string} tenantId 
         * @param {CreateEmployeeDTO} createEmployeeDTO 
         * @param {boolean} [sendMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee(tenantId: string, createEmployeeDTO: CreateEmployeeDTO, sendMail?: boolean, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createEmployee(tenantId, createEmployeeDTO, sendMail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee(tenantId: string, employeeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmployee(tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary download all employees of tenant as excel sheet
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmployees(tenantId: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportEmployees(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get an employee by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {boolean} [includeBenefits] 
         * @param {boolean} [includeRegistrationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeById(tenantId: string, employeeId: string, includeBenefits?: boolean, includeRegistrationToken?: boolean, options?: any): AxiosPromise<EmployeeDTO> {
            return localVarFp.getEmployeeById(tenantId, employeeId, includeBenefits, includeRegistrationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sends the invitation mail of all employees using the given filters
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUsers(tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inviteUsers(tenantId, considerFutureActivations, activeBenefit, activeCustomBenefitId, inactiveBenefit, inactiveCustomBenefitId, userStatus, status, showActiveUser, excludeEmployeeStatus, onlyExpiredRegistrations, filter, department, costCenter, confirmedNewsArticleId, unconfirmedNewsArticleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list employees
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {boolean} [includeBenefits] true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
         * @param {boolean} [includeUpcoming] true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [includeActivationFor] true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
         * @param {string} [includeActivationForCustomBenefitId] id of custom benefit for includeActivationFor
         * @param {string} [includeStatusForNewsArticleId] id of news article to include view status for
         * @param {Array<'status'>} [aggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployees(tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, includeBenefits?: boolean, includeUpcoming?: boolean, includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', includeActivationForCustomBenefitId?: string, includeStatusForNewsArticleId?: string, aggregations?: Array<'status'>, sortBy?: Array<string>, page?: number, pageSize?: number, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options?: any): AxiosPromise<EmployeeListDTO> {
            return localVarFp.listEmployees(tenantId, considerFutureActivations, activeBenefit, activeCustomBenefitId, inactiveBenefit, inactiveCustomBenefitId, userStatus, status, showActiveUser, excludeEmployeeStatus, onlyExpiredRegistrations, includeBenefits, includeUpcoming, includeActivationFor, includeActivationForCustomBenefitId, includeStatusForNewsArticleId, aggregations, sortBy, page, pageSize, filter, department, costCenter, confirmedNewsArticleId, unconfirmedNewsArticleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary resends the invitation mail of an employee in PENDING status
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitationMail(tenantId: string, employeeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendInvitationMail(tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync employees and benefit activations with passed data
         * @param {string} tenantId 
         * @param {SyncEmployeesDTO} syncEmployeesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEmployees(tenantId: string, syncEmployeesDTO: SyncEmployeesDTO, options?: any): AxiosPromise<SyncEmployeesResultDTO> {
            return localVarFp.syncEmployees(tenantId, syncEmployeesDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sets all employees to ACTIVE which are in REGISTERED status
         * @param {string} tenantId 
         * @param {boolean} [considerFutureActivations] true to count activations starting in the future as \&quot;active\&quot;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [activeBenefit] filter for employees with the given benefit active
         * @param {string} [activeCustomBenefitId] id of custom benefit for activeBenefit filter
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [inactiveBenefit] filter for employees with the given benefit inactive
         * @param {string} [inactiveCustomBenefitId] id of custom benefit for inactiveBenefit filter
         * @param {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'} [userStatus] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'} [status] 
         * @param {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'} [showActiveUser] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>} [excludeEmployeeStatus] returns employees without selected status
         * @param {boolean} [onlyExpiredRegistrations] if true returns all employees with an expired registration link
         * @param {boolean} [includeBenefits] true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
         * @param {boolean} [includeUpcoming] true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [includeActivationFor] true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
         * @param {string} [includeActivationForCustomBenefitId] id of custom benefit for includeActivationFor
         * @param {string} [includeStatusForNewsArticleId] id of news article to include view status for
         * @param {Array<'status'>} [aggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [department] 
         * @param {string} [costCenter] 
         * @param {string} [confirmedNewsArticleId] 
         * @param {string} [unconfirmedNewsArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAllRegisteredUsers(tenantId: string, considerFutureActivations?: boolean, activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', activeCustomBenefitId?: string, inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', inactiveCustomBenefitId?: string, userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED', status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED', showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION', excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>, onlyExpiredRegistrations?: boolean, includeBenefits?: boolean, includeUpcoming?: boolean, includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', includeActivationForCustomBenefitId?: string, includeStatusForNewsArticleId?: string, aggregations?: Array<'status'>, sortBy?: Array<string>, page?: number, pageSize?: number, filter?: string, department?: string, costCenter?: string, confirmedNewsArticleId?: string, unconfirmedNewsArticleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlockAllRegisteredUsers(tenantId, considerFutureActivations, activeBenefit, activeCustomBenefitId, inactiveBenefit, inactiveCustomBenefitId, userStatus, status, showActiveUser, excludeEmployeeStatus, onlyExpiredRegistrations, includeBenefits, includeUpcoming, includeActivationFor, includeActivationForCustomBenefitId, includeStatusForNewsArticleId, aggregations, sortBy, page, pageSize, filter, department, costCenter, confirmedNewsArticleId, unconfirmedNewsArticleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeDTO} updateEmployeeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(tenantId: string, employeeId: string, updateEmployeeDTO: UpdateEmployeeDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmployee(tenantId, employeeId, updateEmployeeDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiCreateEmployeeRequest
 */
export interface EmployeeApiCreateEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiCreateEmployee
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateEmployeeDTO}
     * @memberof EmployeeApiCreateEmployee
     */
    readonly createEmployeeDTO: CreateEmployeeDTO

    /**
     * 
     * @type {boolean}
     * @memberof EmployeeApiCreateEmployee
     */
    readonly sendMail?: boolean
}

/**
 * Request parameters for deleteEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiDeleteEmployeeRequest
 */
export interface EmployeeApiDeleteEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiDeleteEmployee
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiDeleteEmployee
     */
    readonly employeeId: string
}

/**
 * Request parameters for exportEmployees operation in EmployeeApi.
 * @export
 * @interface EmployeeApiExportEmployeesRequest
 */
export interface EmployeeApiExportEmployeesRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiExportEmployees
     */
    readonly tenantId: string
}

/**
 * Request parameters for getEmployeeById operation in EmployeeApi.
 * @export
 * @interface EmployeeApiGetEmployeeByIdRequest
 */
export interface EmployeeApiGetEmployeeByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiGetEmployeeById
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiGetEmployeeById
     */
    readonly employeeId: string

    /**
     * 
     * @type {boolean}
     * @memberof EmployeeApiGetEmployeeById
     */
    readonly includeBenefits?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof EmployeeApiGetEmployeeById
     */
    readonly includeRegistrationToken?: boolean
}

/**
 * Request parameters for inviteUsers operation in EmployeeApi.
 * @export
 * @interface EmployeeApiInviteUsersRequest
 */
export interface EmployeeApiInviteUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly tenantId: string

    /**
     * true to count activations starting in the future as \&quot;active\&quot;
     * @type {boolean}
     * @memberof EmployeeApiInviteUsers
     */
    readonly considerFutureActivations?: boolean

    /**
     * filter for employees with the given benefit active
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiInviteUsers
     */
    readonly activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for activeBenefit filter
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly activeCustomBenefitId?: string

    /**
     * filter for employees with the given benefit inactive
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiInviteUsers
     */
    readonly inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for inactiveBenefit filter
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly inactiveCustomBenefitId?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'}
     * @memberof EmployeeApiInviteUsers
     */
    readonly userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'}
     * @memberof EmployeeApiInviteUsers
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'}
     * @memberof EmployeeApiInviteUsers
     */
    readonly showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION'

    /**
     * returns employees without selected status
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>}
     * @memberof EmployeeApiInviteUsers
     */
    readonly excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>

    /**
     * if true returns all employees with an expired registration link
     * @type {boolean}
     * @memberof EmployeeApiInviteUsers
     */
    readonly onlyExpiredRegistrations?: boolean

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly costCenter?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly confirmedNewsArticleId?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiInviteUsers
     */
    readonly unconfirmedNewsArticleId?: string
}

/**
 * Request parameters for listEmployees operation in EmployeeApi.
 * @export
 * @interface EmployeeApiListEmployeesRequest
 */
export interface EmployeeApiListEmployeesRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly tenantId: string

    /**
     * true to count activations starting in the future as \&quot;active\&quot;
     * @type {boolean}
     * @memberof EmployeeApiListEmployees
     */
    readonly considerFutureActivations?: boolean

    /**
     * filter for employees with the given benefit active
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiListEmployees
     */
    readonly activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for activeBenefit filter
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly activeCustomBenefitId?: string

    /**
     * filter for employees with the given benefit inactive
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiListEmployees
     */
    readonly inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for inactiveBenefit filter
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly inactiveCustomBenefitId?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'}
     * @memberof EmployeeApiListEmployees
     */
    readonly userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'}
     * @memberof EmployeeApiListEmployees
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'}
     * @memberof EmployeeApiListEmployees
     */
    readonly showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION'

    /**
     * returns employees without selected status
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>}
     * @memberof EmployeeApiListEmployees
     */
    readonly excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>

    /**
     * if true returns all employees with an expired registration link
     * @type {boolean}
     * @memberof EmployeeApiListEmployees
     */
    readonly onlyExpiredRegistrations?: boolean

    /**
     * true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
     * @type {boolean}
     * @memberof EmployeeApiListEmployees
     */
    readonly includeBenefits?: boolean

    /**
     * true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
     * @type {boolean}
     * @memberof EmployeeApiListEmployees
     */
    readonly includeUpcoming?: boolean

    /**
     * true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiListEmployees
     */
    readonly includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for includeActivationFor
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly includeActivationForCustomBenefitId?: string

    /**
     * id of news article to include view status for
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly includeStatusForNewsArticleId?: string

    /**
     * 
     * @type {Array<'status'>}
     * @memberof EmployeeApiListEmployees
     */
    readonly aggregations?: Array<'status'>

    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeApiListEmployees
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof EmployeeApiListEmployees
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EmployeeApiListEmployees
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly costCenter?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly confirmedNewsArticleId?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiListEmployees
     */
    readonly unconfirmedNewsArticleId?: string
}

/**
 * Request parameters for resendInvitationMail operation in EmployeeApi.
 * @export
 * @interface EmployeeApiResendInvitationMailRequest
 */
export interface EmployeeApiResendInvitationMailRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiResendInvitationMail
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiResendInvitationMail
     */
    readonly employeeId: string
}

/**
 * Request parameters for syncEmployees operation in EmployeeApi.
 * @export
 * @interface EmployeeApiSyncEmployeesRequest
 */
export interface EmployeeApiSyncEmployeesRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiSyncEmployees
     */
    readonly tenantId: string

    /**
     * 
     * @type {SyncEmployeesDTO}
     * @memberof EmployeeApiSyncEmployees
     */
    readonly syncEmployeesDTO: SyncEmployeesDTO
}

/**
 * Request parameters for unlockAllRegisteredUsers operation in EmployeeApi.
 * @export
 * @interface EmployeeApiUnlockAllRegisteredUsersRequest
 */
export interface EmployeeApiUnlockAllRegisteredUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly tenantId: string

    /**
     * true to count activations starting in the future as \&quot;active\&quot;
     * @type {boolean}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly considerFutureActivations?: boolean

    /**
     * filter for employees with the given benefit active
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly activeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for activeBenefit filter
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly activeCustomBenefitId?: string

    /**
     * filter for employees with the given benefit inactive
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly inactiveBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for inactiveBenefit filter
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly inactiveCustomBenefitId?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly userStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CREATED' | 'DELETED'

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'REGISTRATION'}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly showActiveUser?: 'ACTIVE' | 'INACTIVE' | 'REGISTRATION'

    /**
     * returns employees without selected status
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly excludeEmployeeStatus?: Array<'ACTIVE' | 'INACTIVE' | 'REGISTERED' | 'INVITATION_CODE' | 'DELETED'>

    /**
     * if true returns all employees with an expired registration link
     * @type {boolean}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly onlyExpiredRegistrations?: boolean

    /**
     * true to return the fields &#x60;activeBenefits&#x60; and &#x60;activeCustomBenefits&#x60;
     * @type {boolean}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly includeBenefits?: boolean

    /**
     * true to return the fields &#x60;upcomingBenefits&#x60; and &#x60;upcomingCustomBenefits&#x60;
     * @type {boolean}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly includeUpcoming?: boolean

    /**
     * true to return the current or upcoming activation for the benefit in the field &#x60;benefitActivation&#x60;
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly includeActivationFor?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * id of custom benefit for includeActivationFor
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly includeActivationForCustomBenefitId?: string

    /**
     * id of news article to include view status for
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly includeStatusForNewsArticleId?: string

    /**
     * 
     * @type {Array<'status'>}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly aggregations?: Array<'status'>

    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly costCenter?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly confirmedNewsArticleId?: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUnlockAllRegisteredUsers
     */
    readonly unconfirmedNewsArticleId?: string
}

/**
 * Request parameters for updateEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiUpdateEmployeeRequest
 */
export interface EmployeeApiUpdateEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUpdateEmployee
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof EmployeeApiUpdateEmployee
     */
    readonly employeeId: string

    /**
     * 
     * @type {UpdateEmployeeDTO}
     * @memberof EmployeeApiUpdateEmployee
     */
    readonly updateEmployeeDTO: UpdateEmployeeDTO
}

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
    /**
     * 
     * @summary creates and invites a new employee
     * @param {EmployeeApiCreateEmployeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public createEmployee(requestParameters: EmployeeApiCreateEmployeeRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).createEmployee(requestParameters.tenantId, requestParameters.createEmployeeDTO, requestParameters.sendMail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete an employee
     * @param {EmployeeApiDeleteEmployeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public deleteEmployee(requestParameters: EmployeeApiDeleteEmployeeRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).deleteEmployee(requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary download all employees of tenant as excel sheet
     * @param {EmployeeApiExportEmployeesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public exportEmployees(requestParameters: EmployeeApiExportEmployeesRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).exportEmployees(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get an employee by id
     * @param {EmployeeApiGetEmployeeByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public getEmployeeById(requestParameters: EmployeeApiGetEmployeeByIdRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).getEmployeeById(requestParameters.tenantId, requestParameters.employeeId, requestParameters.includeBenefits, requestParameters.includeRegistrationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sends the invitation mail of all employees using the given filters
     * @param {EmployeeApiInviteUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public inviteUsers(requestParameters: EmployeeApiInviteUsersRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).inviteUsers(requestParameters.tenantId, requestParameters.considerFutureActivations, requestParameters.activeBenefit, requestParameters.activeCustomBenefitId, requestParameters.inactiveBenefit, requestParameters.inactiveCustomBenefitId, requestParameters.userStatus, requestParameters.status, requestParameters.showActiveUser, requestParameters.excludeEmployeeStatus, requestParameters.onlyExpiredRegistrations, requestParameters.filter, requestParameters.department, requestParameters.costCenter, requestParameters.confirmedNewsArticleId, requestParameters.unconfirmedNewsArticleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list employees
     * @param {EmployeeApiListEmployeesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public listEmployees(requestParameters: EmployeeApiListEmployeesRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).listEmployees(requestParameters.tenantId, requestParameters.considerFutureActivations, requestParameters.activeBenefit, requestParameters.activeCustomBenefitId, requestParameters.inactiveBenefit, requestParameters.inactiveCustomBenefitId, requestParameters.userStatus, requestParameters.status, requestParameters.showActiveUser, requestParameters.excludeEmployeeStatus, requestParameters.onlyExpiredRegistrations, requestParameters.includeBenefits, requestParameters.includeUpcoming, requestParameters.includeActivationFor, requestParameters.includeActivationForCustomBenefitId, requestParameters.includeStatusForNewsArticleId, requestParameters.aggregations, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, requestParameters.filter, requestParameters.department, requestParameters.costCenter, requestParameters.confirmedNewsArticleId, requestParameters.unconfirmedNewsArticleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary resends the invitation mail of an employee in PENDING status
     * @param {EmployeeApiResendInvitationMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public resendInvitationMail(requestParameters: EmployeeApiResendInvitationMailRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).resendInvitationMail(requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync employees and benefit activations with passed data
     * @param {EmployeeApiSyncEmployeesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public syncEmployees(requestParameters: EmployeeApiSyncEmployeesRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).syncEmployees(requestParameters.tenantId, requestParameters.syncEmployeesDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sets all employees to ACTIVE which are in REGISTERED status
     * @param {EmployeeApiUnlockAllRegisteredUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public unlockAllRegisteredUsers(requestParameters: EmployeeApiUnlockAllRegisteredUsersRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).unlockAllRegisteredUsers(requestParameters.tenantId, requestParameters.considerFutureActivations, requestParameters.activeBenefit, requestParameters.activeCustomBenefitId, requestParameters.inactiveBenefit, requestParameters.inactiveCustomBenefitId, requestParameters.userStatus, requestParameters.status, requestParameters.showActiveUser, requestParameters.excludeEmployeeStatus, requestParameters.onlyExpiredRegistrations, requestParameters.includeBenefits, requestParameters.includeUpcoming, requestParameters.includeActivationFor, requestParameters.includeActivationForCustomBenefitId, requestParameters.includeStatusForNewsArticleId, requestParameters.aggregations, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, requestParameters.filter, requestParameters.department, requestParameters.costCenter, requestParameters.confirmedNewsArticleId, requestParameters.unconfirmedNewsArticleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an employee
     * @param {EmployeeApiUpdateEmployeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public updateEmployee(requestParameters: EmployeeApiUpdateEmployeeRequest, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).updateEmployee(requestParameters.tenantId, requestParameters.employeeId, requestParameters.updateEmployeeDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
