/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ReceiptAuditDTO } from '../dto';
// @ts-ignore
import { ReceiptAuditHistoryDTO } from '../dto';
/**
 * ReceiptAuditApi - axios parameter creator
 * @export
 */
export const ReceiptAuditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a receipt from history for audit
         * @summary Get single receipt from history for audit
         * @param {string} receiptId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} receiptBenefit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryReceipt: async (receiptId: string, receiptBenefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('getHistoryReceipt', 'receiptId', receiptId)
            // verify required parameter 'receiptBenefit' is not null or undefined
            assertParamExists('getHistoryReceipt', 'receiptBenefit', receiptBenefit)
            const localVarPath = `/receipt-audit/history/{receiptId}`
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (receiptBenefit !== undefined) {
                localVarQueryParameter['receiptBenefit'] = receiptBenefit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a receipt that needs to be audited
         * @summary Get receipt for audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptForAudit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/receipt-audit/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a receipt history list for audit
         * @summary Get receipt history list for audit
         * @param {Array<'APPROVED' | 'REJECTED' | 'PENDING'>} [status] 
         * @param {string} [filterString] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReceiptHistory: async (status?: Array<'APPROVED' | 'REJECTED' | 'PENDING'>, filterString?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/receipt-audit/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (filterString !== undefined) {
                localVarQueryParameter['filterString'] = filterString;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceiptAuditApi - functional programming interface
 * @export
 */
export const ReceiptAuditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceiptAuditApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a receipt from history for audit
         * @summary Get single receipt from history for audit
         * @param {string} receiptId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} receiptBenefit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryReceipt(receiptId: string, receiptBenefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptAuditDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryReceipt(receiptId, receiptBenefit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return a receipt that needs to be audited
         * @summary Get receipt for audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptForAudit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptAuditDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptForAudit(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a receipt history list for audit
         * @summary Get receipt history list for audit
         * @param {Array<'APPROVED' | 'REJECTED' | 'PENDING'>} [status] 
         * @param {string} [filterString] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReceiptHistory(status?: Array<'APPROVED' | 'REJECTED' | 'PENDING'>, filterString?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptAuditHistoryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReceiptHistory(status, filterString, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceiptAuditApi - factory interface
 * @export
 */
export const ReceiptAuditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceiptAuditApiFp(configuration)
    return {
        /**
         * Returns a receipt from history for audit
         * @summary Get single receipt from history for audit
         * @param {string} receiptId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} receiptBenefit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryReceipt(receiptId: string, receiptBenefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', options?: any): AxiosPromise<ReceiptAuditDTO> {
            return localVarFp.getHistoryReceipt(receiptId, receiptBenefit, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a receipt that needs to be audited
         * @summary Get receipt for audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptForAudit(options?: any): AxiosPromise<ReceiptAuditDTO> {
            return localVarFp.getReceiptForAudit(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a receipt history list for audit
         * @summary Get receipt history list for audit
         * @param {Array<'APPROVED' | 'REJECTED' | 'PENDING'>} [status] 
         * @param {string} [filterString] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReceiptHistory(status?: Array<'APPROVED' | 'REJECTED' | 'PENDING'>, filterString?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ReceiptAuditHistoryDTO> {
            return localVarFp.listReceiptHistory(status, filterString, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getHistoryReceipt operation in ReceiptAuditApi.
 * @export
 * @interface ReceiptAuditApiGetHistoryReceiptRequest
 */
export interface ReceiptAuditApiGetHistoryReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof ReceiptAuditApiGetHistoryReceipt
     */
    readonly receiptId: string

    /**
     * 
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof ReceiptAuditApiGetHistoryReceipt
     */
    readonly receiptBenefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'
}

/**
 * Request parameters for listReceiptHistory operation in ReceiptAuditApi.
 * @export
 * @interface ReceiptAuditApiListReceiptHistoryRequest
 */
export interface ReceiptAuditApiListReceiptHistoryRequest {
    /**
     * 
     * @type {Array<'APPROVED' | 'REJECTED' | 'PENDING'>}
     * @memberof ReceiptAuditApiListReceiptHistory
     */
    readonly status?: Array<'APPROVED' | 'REJECTED' | 'PENDING'>

    /**
     * 
     * @type {string}
     * @memberof ReceiptAuditApiListReceiptHistory
     */
    readonly filterString?: string

    /**
     * 
     * @type {number}
     * @memberof ReceiptAuditApiListReceiptHistory
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ReceiptAuditApiListReceiptHistory
     */
    readonly pageSize?: number
}

/**
 * ReceiptAuditApi - object-oriented interface
 * @export
 * @class ReceiptAuditApi
 * @extends {BaseAPI}
 */
export class ReceiptAuditApi extends BaseAPI {
    /**
     * Returns a receipt from history for audit
     * @summary Get single receipt from history for audit
     * @param {ReceiptAuditApiGetHistoryReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptAuditApi
     */
    public getHistoryReceipt(requestParameters: ReceiptAuditApiGetHistoryReceiptRequest, options?: AxiosRequestConfig) {
        return ReceiptAuditApiFp(this.configuration).getHistoryReceipt(requestParameters.receiptId, requestParameters.receiptBenefit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a receipt that needs to be audited
     * @summary Get receipt for audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptAuditApi
     */
    public getReceiptForAudit(options?: AxiosRequestConfig) {
        return ReceiptAuditApiFp(this.configuration).getReceiptForAudit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a receipt history list for audit
     * @summary Get receipt history list for audit
     * @param {ReceiptAuditApiListReceiptHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptAuditApi
     */
    public listReceiptHistory(requestParameters: ReceiptAuditApiListReceiptHistoryRequest = {}, options?: AxiosRequestConfig) {
        return ReceiptAuditApiFp(this.configuration).listReceiptHistory(requestParameters.status, requestParameters.filterString, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
