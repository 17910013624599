import { DateTime } from 'luxon';
import { BenefitDTOBenefitEnum, FitnessBenefitDetailsDTO, FitnessBenefitDetailsDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { createContext, useContext, useMemo } from 'react';

export const FITNESS_REGISTER_AMOUNT = 'fitness-register-amount';
export const FitnessStatusConfig = {
  ...FitnessBenefitDetailsDTOStatusEnum,
  Registering: 'REGISTERING',
} as const;

export const getFitnessStatus = (status: FitnessBenefitDetailsDTOStatusEnum, monthlyBudget: number): FitnessStatus => {
  const storedRegistrationAmount = localStorage.getItem(FITNESS_REGISTER_AMOUNT);
  if (status === FitnessBenefitDetailsDTOStatusEnum.Inactive && Number(storedRegistrationAmount) === monthlyBudget) {
    return FitnessStatusConfig.Registering;
  }
  return status;
};

export type FitnessStatus = (typeof FitnessStatusConfig)[keyof typeof FitnessStatusConfig];
interface FitnessQuery {
  status: FitnessStatus;
  startDate?: Date;
  endDate?: Date;
  overrideId?: string;
  isLoading: boolean;
  monthlyBudget?: number;
  registrationLink: string;
  deadline?: Date;
  nextActivationMonth?: Date;
}

export const useFitnessQuery = (): FitnessQuery => {
  const fitnessQuery = useEmployeeQuery(['benefits', 'fitness', apis.benefits.getBenefit.name], params =>
    apis.benefits
      .getBenefit({
        ...params,
        benefit: BenefitDTOBenefitEnum.Fitness,
      })
      .then(res => res.data),
  );

  const data = fitnessQuery.data?.details as FitnessBenefitDetailsDTO;

  return useMemo(() => {
    return {
      status: getFitnessStatus(data?.status, data?.monthlyBudget),
      startDate: data?.startDate ? DateTime.fromISO(data?.startDate).toJSDate() : undefined,
      endDate: data?.endDate ? DateTime.fromISO(data?.endDate).toJSDate() : undefined,
      overrideId: data?.overrideId,
      isLoading: fitnessQuery.isLoading,
      monthlyBudget: data?.monthlyBudget,
      deadlineDate: DateTime.fromISO(data?.deadlineDate).toJSDate(),
      nextStartDate: DateTime.fromISO(data?.nextStartDate).toJSDate(),
      registrationLink: data?.registrationLink || '#',
    };
  }, [data, fitnessQuery.isLoading]);
};

export interface FitnessState {
  status: FitnessStatus;
  startDate?: Date;
  endDate?: Date;
  overrideId?: string;
  isLoading: boolean;
  registrationLink: string;
  monthlyBudget?: number;
  deadlineDate?: Date;
  nextStartDate?: Date;
}

const FitnessStateContext = createContext<FitnessState | undefined>(undefined);

export const FitnessStateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const fitnessState = useFitnessQuery();

  const context = useMemo(
    () => ({
      ...fitnessState,
    }),
    [fitnessState],
  );
  return <FitnessStateContext.Provider value={context}>{children}</FitnessStateContext.Provider>;
};

export function useFitnessState(): FitnessState {
  const ctx = useContext(FitnessStateContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useFitnessState must be used within a FitnessStateProvider');
  }
  return ctx;
}
